<template>
  <div class="flex gap-2 items-center">
    <LazyUAvatar
      :src="author.avatar"
      :alt="fullName"
      size="lg"
      chip-color="pink"
      :chip-text="`Top ${position + 1}`"
      chip-position="bottom-left"
      loading="lazy"
    />
    <div class="flex flex-col items-start">
      <UButton
        color="pink"
        class="font-semibold"
        variant="link"
        :to="`/users/${author.username}`"
        :padded="false"
        >{{ fullName }}</UButton
      >
      <span class="text-sm text-gray-500">{{ author.username }}</span>
    </div>
  </div>
  <div class="flex w-full justify-between mt-0">
    <UButton
      icon="i-heroicons-eye"
      size="xs"
      color="white"
      variant="soft"
      :trailing="false"
      :padded="false"
      >{{ formatCompactNumber(author.views) }}</UButton
    >
    <UButton
      icon="i-heroicons-bookmark"
      size="xs"
      color="white"
      variant="soft"
      :trailing="false"
      :padded="false"
      >{{ author.bookmarks }}</UButton
    >
    <UButton
      icon="i-heroicons-document-chart-bar"
      size="xs"
      color="white"
      variant="soft"
      :trailing="false"
      :padded="false"
      >{{ author.articles }}</UButton
    >
    <UButton
      icon="i-heroicons-hand-raised"
      size="xs"
      color="white"
      variant="soft"
      :trailing="false"
      :padded="false"
      >{{ author.claps }}</UButton
    >
  </div>
</template>

<script lang="ts" setup>
import type { TTopAuthor } from "~/features/post";
import { formatCompactNumber } from "~/utils/number";

type Props = {
  author: TTopAuthor;
  position: number;
};

const props = defineProps<Props>();

const fullName = computed(() => `${props.author.firstName} ${props.author.lastName}`);
</script>
